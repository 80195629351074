export const cancelPrevious = (g, msg) => {
  if (g.cancel) {
    try {
      g.cancel(msg)
      g.cancel = null
    } catch (err) {
      /**/
    }
  }
}
export default cancelPrevious
