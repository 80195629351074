import { applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import configureStore from './configureStore'
import sagas from './sagas'

// initialize middlewares
let middlewares = []

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__ || {}
const sagaMiddleware = createSagaMiddleware()

middlewares.push(sagaMiddleware)
if (process.env.NODE_ENV !== 'production') {
  const createLogger = require('redux-logger').createLogger
  const logger = createLogger({ collapsed: true })
  middlewares.push(logger)
}

let middleware = applyMiddleware(...middlewares)

// add the redux dev tools
if (process.env.NODE_ENV !== 'production' && window.devToolsExtension) {
  middleware = compose(middleware, window.devToolsExtension())
}

const store = configureStore(preloadedState, middleware)
sagaMiddleware.run(sagas)

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

export default store
