import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Loading extends Component {
  render() {
    const { className, loadingText } = this.props
    return (
      <div className={cx('loading-container', { [className]: !!className })}>
        <div className="loader loader-circle" />
        {!!loadingText && <div className="loading-text">{loadingText}</div>}
      </div>
    )
  }
}
Loading.defaultProps = {
  loadingText: 'Loading...',
}
Loading.propTypes = {
  className: PropTypes.string,
  loadingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
}

export default Loading
