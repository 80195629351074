import React from 'react'
import MenuContainer from './Menu/index'
import SliderContainer from './Slider/index'
import Timelapse from './Timelapse/index'
import useWindowSize from 'components/hooks/useWindowSize'

const App = () => {
  let { orientation, innerWidth, innerHeight } = useWindowSize()
  return (
    <div className="app">
      <MenuContainer />
      <Timelapse orientation={orientation} innerWidth={innerWidth} innerHeight={innerHeight} />
      <SliderContainer />
    </div>
  )
}

export default App
