import LOGOUT from './constants/LOGOUT'
import FETCH_IMAGES_START from './constants/FETCH_IMAGES_START'
import FETCH_IMAGES_SUCCESS from './constants/FETCH_IMAGES_SUCCESS'
import { FETCH_NEXT_IMAGES_SUCCESS, FETCH_PREV_IMAGES_SUCCESS, TIMELAPSE_SPEED } from './constants/index'
import SET_TIMELAPSE_STATE from './constants/SET_TIMELAPSE_STATE'
import SET_GROUP_STATE from './constants/SET_GROUP_STATE'
import UPDATE_LOADED_IMAGES from './constants/UPDATE_LOADED_IMAGES'
import SET_SPEED from './constants/SET_SPEED'
import UPDATE_DATE from './constants/UPDATE_DATE'
import formatDate from 'components/helpers/formatDate'
import { GROUP_SIZE } from 'components/App/constants/index'
import { isBefore, differenceInMinutes, addDays, subDays } from 'date-fns'
import update from 'immutability-helper'
import storage from 'components/helpers/localStorage'
const ALWAYS = true
const initialState = {
  name: 'app-reducer',
  isLoggingOut: false,
  images: {
    isLoading: false,
    error: '',
  },
  timelapseState: {
    isPlaying: false,
    isDone: false,
    imageInQueue: '',
    direction: 'forward',
    areControlsVisible: true,
    nextStartIndexOnLoad: undefined,
  },
  groupState: {
    imagesGroup: [],
    groupStartIndex: 0,
    groupEndIndex: GROUP_SIZE,
    placeholderImage: '',
  },
  loadedImagesMap: {},
  speed: Number(storage.getItem(TIMELAPSE_SPEED)) || 1000,
  date: formatDate(new Date(), 'YYYY-MM-DD'),
  replayStartDate: formatDate(new Date(), 'YYYY-MM-DD'),
  prevDate: formatDate(subDays(new Date(), 1), 'YYYY-MM-DD'),
  nextDate: formatDate(addDays(new Date(), 1), 'YYYY-MM-DD'),
  startDate: '2018-01-01',
  currentTimeInMenu: '',
  datesMap: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return update(initialState, {
        isLoggingOut: { $set: true },
      })
    case FETCH_NEXT_IMAGES_SUCCESS:
    case FETCH_PREV_IMAGES_SUCCESS:
      return update(state, {
        datesMap: {
          [action.params.date]: { $set: action.data },
        },
      })
    case FETCH_IMAGES_START:
      return update(state, {
        images: {
          isLoading: { $set: true },
          error: { $set: '' },
        },
        timelapseState: {
          isPlaying: { $set: false },
          isDone: { $set: false },
          imageInQueue: { $set: '' },
          direction: { $set: state.currentTimeInMenu ? 'forward' : state.timelapseState.direction },
          areControlsVisible: { $set: true },
          nextStartIndexOnLoad: { $set: undefined },
        },
        groupState: {
          imagesGroup: { $set: [] },
          placeholderImage: { $set: '' },
          groupStartIndex: { $set: 0 },
          groupEndIndex: { $set: GROUP_SIZE },
        },
        loadedImagesMap: { $set: {} },
      })
    case FETCH_IMAGES_SUCCESS:
      if (ALWAYS) {
        const { direction } = state.timelapseState
        const images = action.data
          ? action.data
              .map(item => {
                return {
                  // created: formatDate(new Date(item.created_unix * 1000), 'YYYY-MM-DD HH:mm'),
                  created: item.created,
                  url:
                    item.store.store.corrected1080 ||
                    item.store.store.raw1080 ||
                    item.store.store.corrected720 ||
                    item.store.store.raw720 ||
                    item.store.store.raw_full,
                  uuid: item.uid,
                  // mm: formatDate(new Date(item.created_unix * 1000), 'HH:mm'),
                  mm: formatDate(new Date(item.created), 'HH:mm'),
                }
              })
              .reverse()
          : []
        let newState = update(state, {
          images: {
            isLoading: { $set: false },
            list: {
              $set: direction === 'forward' ? images : [...images.reverse()],
            },
          },
          datesMap: {
            [state.date]: { $set: action.data || [] },
          },
        })
        if (images.length) {
          let { groupStartIndex, groupEndIndex } = state.groupState
          if (state.currentTimeInMenu) {
            const selectedDateInMenu = new Date(`2019-04-18 ${state.currentTimeInMenu.split(' ')[1]}`)
            let beforeTime = ''
            let afterTime = ''
            for (let i = 0; i < images.length; i++) {
              const img = images[i]
              const time = img.created.split(' ')[1]
              const date = new Date(`2019-04-18 ${time}`)
              if (isBefore(date, selectedDateInMenu)) {
                beforeTime = date
              } else {
                afterTime = date
                if (beforeTime) {
                  if (
                    Math.abs(differenceInMinutes(beforeTime, selectedDateInMenu)) <=
                    Math.abs(differenceInMinutes(afterTime, selectedDateInMenu))
                  ) {
                    groupStartIndex = i - 1
                  } else {
                    groupStartIndex = i
                  }
                  groupEndIndex = groupStartIndex + GROUP_SIZE
                  break
                }
              }
            }
          }
          const imagesGroup = images.slice(groupStartIndex, groupEndIndex).map((image, i) => ({
            ...image,
            className: i === 0 ? 'current' : 'next',
          }))
          newState = update(newState, {
            groupState: {
              imagesGroup: { $set: imagesGroup },
              groupStartIndex: { $set: groupStartIndex },
              groupEndIndex: { $set: groupEndIndex },
              placeholderImage: { $set: images[groupStartIndex].url },
            },
          })
        }
        return newState
      }
      break
    case SET_TIMELAPSE_STATE:
      if (ALWAYS) {
        const directionChanged =
          action.timelapseState.direction && action.timelapseState.direction !== state.timelapseState.direction
        let imagesProps = {}
        if (directionChanged) {
          const currentImages = [...state.images.list]
          imagesProps = {
            images: {
              ...state.images,
              list: [...currentImages.reverse()],
            },
          }
        }
        return {
          ...state,
          timelapseState: {
            ...state.timelapseState,
            ...action.timelapseState,
          },
          ...imagesProps,
        }
      }
      break
    case SET_GROUP_STATE:
      return {
        ...state,
        groupState: {
          ...state.groupState,
          ...action.groupState,
        },
      }
    case UPDATE_LOADED_IMAGES:
      return {
        ...state,
        loadedImagesMap: {
          ...state.loadedImagesMap,
          ...action.payload,
        },
      }
    case SET_SPEED:
      return update(state, {
        speed: { $set: action.speed },
      })
    case UPDATE_DATE:
      return update(state, {
        date: { $set: action.date },
        replayStartDate: { $set: action.currentTimeInMenu === 'START' ? state.replayStartDate : action.date },
        prevDate: { $set: formatDate(subDays(new Date(action.date), 1), 'YYYY-MM-DD') },
        nextDate: { $set: formatDate(addDays(new Date(action.date), 1), 'YYYY-MM-DD') },
        currentTimeInMenu: {
          $set:
            action.currentTimeInMenu === 'START'
              ? ''
              : state.groupState.imagesGroup.length
              ? state.groupState.imagesGroup.find(image => image.className === 'current').created
              : state.currentTimeInMenu,
        },
        images: {
          isLoading: { $set: true },
        },
      })
    default:
      return { ...state }
  }
}
