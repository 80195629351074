import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Icon from 'common/Icon/index'
import Speed from './Speed'
import { TIMELAPSE_SPEED } from 'components/App/constants/index'
import storage from 'components/helpers/localStorage'

const SPEEDS = [
  { speed: 1750, label: '0.25' },
  { speed: 1500, label: '0.5' },
  { speed: 1250, label: '0.75' },
  { speed: 1000, label: '1' },
  { speed: 750, label: '1.25' },
  { speed: 500, label: '1.5' },
  { speed: 250, label: '1.75' },
  { speed: 100, label: '2' },
]

class SpeedMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      speed: props.speed,
    }
  }
  componentWillUpdate(nextProps) {
    if (nextProps.speed && !this.props.speed) {
      this.setState({ speed: nextProps.speed })
      storage.setItem(TIMELAPSE_SPEED, nextProps.speed)
    }
  }

  render() {
    const { speed } = this.state
    const propSpeed = this.props.speed
    const { label: currentLabel } = SPEEDS.find(item => item.speed === speed) || { label: '' }
    return (
      <Fragment>
        <li className="main-list speed-dropdown">
          <a href="#" onClick={e => e.preventDefault()}>
            <span>Speed: {currentLabel}x</span>
            <Icon icon="dropdown" />
          </a>
          {!!propSpeed && (
            <ul className="dropdown">
              {SPEEDS.map(item => {
                return <Speed key={item.label} label={item.label} currentLabel={currentLabel} speed={item.speed} />
              })}
            </ul>
          )}
        </li>
      </Fragment>
    )
  }
}
SpeedMenu.propTypes = {
  actions: PropTypes.object,
  speed: PropTypes.number,
}

const mapStateToProps = state => ({
  speed: state.app.speed,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeedMenu)
