import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import store from '../shared/store'
import App from 'components/App/index'
import 'src/shared/styles/main.sass'
import 'src/shared/styles/custom_icons/style.css'

axios.defaults.withCredentials = true
axios.defaults.headers.common['Accept'] = 'application/json'

const root = document.getElementById('root')

const renderApp = App => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>{App}</BrowserRouter>
    </Provider>,
    root
  )
}

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('components/App/index', () => {
      const App = require('components/App/index').default
      renderApp(<App />)
    })
  }
}
renderApp(<App />)
