import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Icon = ({ icon, className, prefix, onClick }) => (
  <i className={cx(`${prefix}${icon}`, { [className]: !!className })} aria-hidden="true" onClick={onClick} />
)

Icon.defaultProps = {
  onClick: () => {},
  prefix: 'icon cc-',
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  prefix: PropTypes.string,
}

export default Icon
