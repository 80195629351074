import axios from 'axios'
import cancelPrevious from 'components/helpers/cancelPrevious'
import getCancelConfig from 'components/helpers/getCancelConfig'
import g from 'components/global'

g.images = {
  cancel: null,
}
const CancelToken = axios.CancelToken
const fetchImages = (params = {}, options = {}) => {
  const base = options.apiBase || ''
  cancelPrevious(g.images, 'CANCELLED')
  const cancelConfig = getCancelConfig(CancelToken, g.images)
  const config = options.auth ? { ...cancelConfig, auth: options.auth } : { ...cancelConfig }
  const quantity = params.n ? `&n=${params.n}` : ''
  let url = `${base}/fetch.php?identifier=${params.identifier || 'testscript'}&date=${params.date}${quantity}`
  if (process.env.NODE_ENV !== 'production') {
    return axios
      .post('/camcontrol', { ...params }, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } else {
    return axios
      .get(url, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
}

g.prevImages = {
  cancel: null,
}
const fetchPrevImages = (params = {}, options = {}) => {
  const base = options.apiBase || ''
  cancelPrevious(g.prevImages, 'CANCELLED')
  const cancelConfig = getCancelConfig(CancelToken, g.prevImages)
  const config = options.auth ? { ...cancelConfig, auth: options.auth } : { ...cancelConfig }
  const quantity = params.n ? `&n=${params.n}` : ''
  let url = `${base}/fetch.php?identifier=${params.identifier || 'testscript'}&date=${params.date}${quantity}`
  if (process.env.NODE_ENV !== 'production') {
    return axios
      .post('/camcontrol', { ...params }, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } else {
    return axios
      .get(url, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
}

g.nextImages = {
  cancel: null,
}
const fetchNextImages = (params = {}, options = {}) => {
  const base = options.apiBase || ''
  cancelPrevious(g.nextImages, 'CANCELLED')
  const cancelConfig = getCancelConfig(CancelToken, g.nextImages)
  const config = options.auth ? { ...cancelConfig, auth: options.auth } : { ...cancelConfig }
  const quantity = params.n ? `&n=${params.n}` : ''
  let url = `${base}/fetch.php?identifier=${params.identifier || 'testscript'}&date=${params.date}${quantity}`
  if (process.env.NODE_ENV !== 'production') {
    return axios
      .post('/camcontrol', { ...params }, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  } else {
    return axios
      .get(url, config)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
}

export default { fetchImages, fetchPrevImages, fetchNextImages }
