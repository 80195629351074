import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import Icon from 'common/Icon/index'
import setGroupState from 'components/App/actions/setGroupState'
import setTimelapseState from 'components/App/actions/setTimelapseState'
import { GROUP_SIZE } from 'components/App/constants/index'

class TimeMenu extends Component {
  componentDidUpdate(prevProps) {
    const { isPlaying, images, actions, totalImages } = this.props
    const { imageInQueue, nextStartIndexOnLoad, nextEndIndexOnLoad } = this.props
    if (prevProps.imageInQueue && !imageInQueue) {
      if (nextStartIndexOnLoad !== undefined && !isPlaying) {
        const nextStartIndex = nextStartIndexOnLoad
        const nextEndIndex = nextEndIndexOnLoad
        const nextImages = images.slice(nextStartIndex, nextEndIndex).map((image, i) => ({
          ...image,
          className: i === 0 ? 'current' : 'next',
        }))
        const prevImages =
          nextStartIndex !== 0
            ? images.slice(Math.max(0, nextStartIndex - GROUP_SIZE - 1), nextStartIndex).map(image => ({
                ...image,
                className: 'prev',
              }))
            : []

        actions.setGroupState({
          imagesGroup: [...prevImages, ...nextImages],
          groupStartIndex: nextStartIndex,
          groupEndIndex: nextEndIndex,
        })
        if (nextStartIndex >= totalImages - 1) {
          actions.setTimelapseState({
            isDone: true,
            areControlsVisible: true,
          })
        }
      }
    }
  }

  _onPrevClick = e => {
    e.preventDefault()
    const { actions, isPlaying, isDone, groupStartIndex, groupEndIndex, placeholderImage, images } = this.props
    const { loadedImagesMap, imageInQueue } = this.props
    const disabled = isPlaying || !!placeholderImage || groupStartIndex === 0 || !!imageInQueue
    if (!disabled) {
      const nextStartIndex = groupStartIndex - 1
      const nextEndIndex = groupEndIndex - 1
      const nextImages = images.slice(nextStartIndex, nextEndIndex).map((image, i) => ({
        ...image,
        className: i === 0 ? 'current' : 'next',
      }))
      const nextImage = nextImages[0]
      if (loadedImagesMap[nextImage.url]) {
        const prevImages =
          nextStartIndex !== 0
            ? images.slice(Math.max(0, nextStartIndex - GROUP_SIZE - 1), nextStartIndex).map(image => ({
                ...image,
                className: 'prev',
              }))
            : []

        actions.setGroupState({
          imagesGroup: [...prevImages, ...nextImages],
          groupStartIndex: nextStartIndex,
          groupEndIndex: nextEndIndex,
        })
        if (isDone) {
          actions.setTimelapseState({
            isDone: false,
          })
        }
      } else {
        actions.setTimelapseState({
          imageInQueue: nextImage.url,
          nextStartIndexOnLoad: nextStartIndex,
          nextEndIndexOnLoad: nextEndIndex,
        })
      }
    }
  }
  _onNextClick = e => {
    e.preventDefault()
    const { actions, isPlaying, groupEndIndex, groupStartIndex, placeholderImage, totalImages, images } = this.props
    const { loadedImagesMap, imageInQueue } = this.props
    const disabled = isPlaying || !!placeholderImage || groupStartIndex >= totalImages - 1 || !!imageInQueue
    if (!disabled) {
      const nextStartIndex = groupStartIndex + 1
      const nextEndIndex = groupEndIndex + 1
      const nextImages = images.slice(nextStartIndex, nextEndIndex).map((image, i) => ({
        ...image,
        className: i === 0 ? 'current' : 'next',
      }))
      const nextImage = nextImages[0]
      if (loadedImagesMap[nextImage.url]) {
        const prevImages =
          nextStartIndex !== 0
            ? images.slice(Math.max(0, nextStartIndex - GROUP_SIZE - 1), nextStartIndex).map(image => ({
                ...image,
                className: 'prev',
              }))
            : []

        actions.setGroupState({
          imagesGroup: [...prevImages, ...nextImages],
          groupStartIndex: nextStartIndex,
          groupEndIndex: nextEndIndex,
        })
        if (nextStartIndex >= totalImages - 1) {
          actions.setTimelapseState({
            isDone: true,
            areControlsVisible: true,
          })
        }
      } else {
        actions.setTimelapseState({
          imageInQueue: nextImage.url,
          nextStartIndexOnLoad: nextStartIndex,
          nextEndIndexOnLoad: nextEndIndex,
        })
      }
    }
  }
  render() {
    const { isPlaying, imagesGroup, imageInQueue, groupStartIndex, placeholderImage, totalImages } = this.props
    const { currentTimeInMenu } = this.props
    const activeImage = imagesGroup.find(image => image.className === 'current') || {}
    const disabled = isPlaying || !!placeholderImage || !!imageInQueue || !imagesGroup[0]
    const isPrevDisabled = disabled || groupStartIndex === 0
    const isNextDisbaled = disabled || groupStartIndex >= totalImages - 1
    const placeHolderTime = currentTimeInMenu ? currentTimeInMenu.split(' ')[1] : '--:--'
    return (
      <Fragment>
        <li className={cx('nav-arrow nav-arrow-left', { disabled: isPrevDisabled })}>
          <a href="#" onClick={this._onPrevClick}>
            <Icon icon="prev" />
          </a>
        </li>
        <li className="mm-dropdown no-action px-5">
          <a href="#" onClick={e => e.preventDefault()}>
            <span>{activeImage.mm || placeHolderTime}</span>
          </a>
        </li>
        <li className={cx('nav-arrow nav-arrow-right', { disabled: isNextDisbaled })}>
          <a href="#" onClick={this._onNextClick}>
            <Icon icon="next" />
          </a>
        </li>
      </Fragment>
    )
  }
}
TimeMenu.propTypes = {
  actions: PropTypes.object,
  isPlaying: PropTypes.bool,
  isDone: PropTypes.bool,
  direction: PropTypes.string,
  imagesGroup: PropTypes.array,
  groupStartIndex: PropTypes.number,
  groupEndIndex: PropTypes.number,
  placeholderImage: PropTypes.string,
  totalImages: PropTypes.number,
  images: PropTypes.array,
  loadedImagesMap: PropTypes.object,
  imageInQueue: PropTypes.string,
  nextStartIndexOnLoad: PropTypes.number,
  nextEndIndexOnLoad: PropTypes.number,
  currentTimeInMenu: PropTypes.string,
}

const mapStateToProps = state => ({
  isPlaying: state.app.timelapseState.isPlaying,
  isDone: state.app.timelapseState.isDone,
  direction: state.app.timelapseState.direction,
  totalImages: state.app.images.list ? state.app.images.list.length : 0,
  imagesGroup: state.app.groupState.imagesGroup,
  groupStartIndex: state.app.groupState.groupStartIndex,
  groupEndIndex: state.app.groupState.groupEndIndex,
  placeholderImage: state.app.groupState.placeholderImage,
  loadedImagesMap: state.app.loadedImagesMap,
  imageInQueue: state.app.timelapseState.imageInQueue,
  nextStartIndexOnLoad: state.app.timelapseState.nextStartIndexOnLoad,
  nextEndIndexOnLoad: state.app.timelapseState.nextEndIndexOnLoad,
  currentTimeInMenu: state.app.currentTimeInMenu,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setGroupState, setTimelapseState }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeMenu)
