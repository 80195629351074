import { FETCH_PREV_IMAGES_START, FETCH_NEXT_IMAGES_START, CLEAR_CURRENTTIME_IN_MENU } from '../constants/index'
export const fetchPrevImages = params => {
  return { type: FETCH_PREV_IMAGES_START, params }
}
export const fetchNextImages = params => {
  return { type: FETCH_NEXT_IMAGES_START, params }
}
export const clearCurrentTimeInMenu = () => {
  return { type: CLEAR_CURRENTTIME_IN_MENU }
}
