export const GROUP_SIZE = 5

export const FETCH_PREV_IMAGES_START = 'FETCH_PREV_IMAGES_START'
export const FETCH_PREV_IMAGES_SUCCESS = 'FETCH_PREV_IMAGES_SUCCESS'
export const FETCH_PREV_IMAGES_ERROR = 'FETCH_PREV_IMAGES_ERROR'

export const FETCH_NEXT_IMAGES_START = 'FETCH_NEXT_IMAGES_START'
export const FETCH_NEXT_IMAGES_SUCCESS = 'FETCH_NEXT_IMAGES_SUCCESS'
export const FETCH_NEXT_IMAGES_ERROR = 'FETCH_NEXT_IMAGES_ERROR'

export const TIMELAPSE_SPEED = 'TIMELAPSE_SPEED'