import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cx from 'classnames'
import Icon from 'common/Icon/index'
import { isAfter, addMonths, isBefore, subMonths } from 'date-fns'
import formatDate from 'components/helpers/formatDate'
import updateDate from 'components/App/actions/updateDate'
import DATE_FORMAT_1 from 'components/App/constants/DATE_FORMAT_1'

class MonthMenu extends Component {
  _onPrevClick = e => {
    e.preventDefault()
    const { date, startDate, actions } = this.props
    const minDate = new Date(startDate)
    const currentDate = new Date(date)
    const prevDate = subMonths(currentDate, 1)
    const isPrevDisabled = isBefore(prevDate, minDate)
    if (!isPrevDisabled) {
      actions.updateDate(formatDate(prevDate, DATE_FORMAT_1))
    }
  }
  _onNextClick = e => {
    e.preventDefault()
    const { date, actions } = this.props
    const today = new Date()
    const currentDate = new Date(date)
    const nextDate = addMonths(currentDate, 1)
    const isNextDisbaled = isAfter(nextDate, today)
    if (!isNextDisbaled) {
      actions.updateDate(formatDate(nextDate, DATE_FORMAT_1))
    }
  }
  render() {
    const { date, startDate } = this.props
    const today = new Date()
    const currentDate = new Date(date)
    const minDate = new Date(startDate)
    const nextDate = addMonths(currentDate, 1)
    const prevDate = subMonths(currentDate, 1)
    const isPrevDisabled = isBefore(prevDate, minDate)
    const isNextDisbaled = isAfter(nextDate, today)
    const month = formatDate(currentDate, 'MMM')
    return (
      <Fragment>
        <li className={cx('nav-arrow nav-arrow-left', { disabled: isPrevDisabled })}>
          <a href="#" onClick={this._onPrevClick}>
            <Icon icon="prev" />
          </a>
        </li>
        <li className="no-action px-5">
          <a href="#" onClick={e => e.preventDefault()}>
            <span>{month}</span>
          </a>
        </li>
        <li className={cx('nav-arrow nav-arrow-right', { disabled: isNextDisbaled })}>
          <a href="#" onClick={this._onNextClick}>
            <Icon icon="next" />
          </a>
        </li>
      </Fragment>
    )
  }
}
MonthMenu.propTypes = {
  actions: PropTypes.object,
  date: PropTypes.string,
  startDate: PropTypes.string,
}

const mapStateToProps = state => ({
  date: state.app.date,
  startDate: state.app.startDate,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ updateDate }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthMenu)
