import FETCH_IMAGES_START from './constants/FETCH_IMAGES_START'
import FETCH_IMAGES_ERROR from './constants/FETCH_IMAGES_ERROR'
import FETCH_IMAGES_SUCCESS from './constants/FETCH_IMAGES_SUCCESS'

import {
  FETCH_PREV_IMAGES_START,
  FETCH_PREV_IMAGES_SUCCESS,
  FETCH_PREV_IMAGES_ERROR,
  FETCH_NEXT_IMAGES_START,
  FETCH_NEXT_IMAGES_SUCCESS,
  FETCH_NEXT_IMAGES_ERROR,
} from './constants/index'

import API_ERROR_MESSAGE from 'components/App/constants/API_ERROR_MESSAGE'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import API from './service'

const handleError = ({ query, type, error = API_ERROR_MESSAGE, error_track_id }) =>
  put({ query, type, error, error_track_id })

export function* fetchImages(...args) {
  const [options, action] = args
  const { params } = action
  const { date, datesMap } = yield select(state => state.app)
  try {
    if (datesMap[date]) {
      yield put({ type: FETCH_IMAGES_SUCCESS, data: datesMap[date] })
    } else {
      const { response, error } = yield call(API.fetchImages, params, options)
      if (error) {
        if (error.response && error.response.data) {
          const data = error.response.data
          const message = data.error || data.message
          const error_track_id = data.error_track_id
          yield handleError({ type: FETCH_IMAGES_ERROR, error: message, error_track_id })
        } else {
          yield handleError({ type: FETCH_IMAGES_ERROR })
        }
      } else {
        if (response.data) {
          yield put({ type: FETCH_IMAGES_SUCCESS, data: response.data })
        } else {
          yield handleError({ type: FETCH_IMAGES_ERROR })
        }
      }
    }
  } catch (e) {
    yield handleError({ type: FETCH_IMAGES_ERROR })
  }
}
export function* fetchPrevImages(...args) {
  const [options, action] = args
  const { params } = action
  const { prevDate, datesMap } = yield select(state => state.app)
  try {
    if (datesMap[prevDate]) {
      yield put({ type: FETCH_PREV_IMAGES_SUCCESS, data: datesMap[prevDate], params })
    } else {
      const { response, error } = yield call(API.fetchPrevImages, params, options)
      if (error) {
        if (error.response && error.response.data) {
          const data = error.response.data
          const message = data.error || data.message
          const error_track_id = data.error_track_id
          yield handleError({ type: FETCH_PREV_IMAGES_ERROR, error: message, error_track_id })
        } else {
          yield handleError({ type: FETCH_PREV_IMAGES_ERROR })
        }
      } else {
        if (response.data) {
          yield put({ type: FETCH_PREV_IMAGES_SUCCESS, data: response.data, params })
        } else {
          yield handleError({ type: FETCH_PREV_IMAGES_ERROR })
        }
      }
    }
  } catch (e) {
    yield handleError({ type: FETCH_PREV_IMAGES_ERROR })
  }
}

export function* fetchNextImages(...args) {
  const [options, action] = args
  const { params } = action
  const { nextDate, datesMap } = yield select(state => state.app)
  try {
    if (datesMap[nextDate]) {
      yield put({ type: FETCH_NEXT_IMAGES_SUCCESS, data: datesMap[nextDate], params })
    } else {
      const { response, error } = yield call(API.fetchNextImages, params, options)
      if (error) {
        if (error.response && error.response.data) {
          const data = error.response.data
          const message = data.error || data.message
          const error_track_id = data.error_track_id
          yield handleError({ type: FETCH_NEXT_IMAGES_ERROR, error: message, error_track_id })
        } else {
          yield handleError({ type: FETCH_NEXT_IMAGES_ERROR })
        }
      } else {
        if (response.data) {
          yield put({ type: FETCH_NEXT_IMAGES_SUCCESS, data: response.data, params })
        } else {
          yield handleError({ type: FETCH_NEXT_IMAGES_ERROR })
        }
      }
    }
  } catch (e) {
    yield handleError({ type: FETCH_NEXT_IMAGES_ERROR })
  }
}

export default function* appSaga(options) {
  yield takeEvery(FETCH_IMAGES_START, fetchImages, options)
  yield takeEvery(FETCH_PREV_IMAGES_START, fetchPrevImages, options)
  yield takeEvery(FETCH_NEXT_IMAGES_START, fetchNextImages, options)
}
