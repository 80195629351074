import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export class Slider extends Component {
  render() {
    return <div className="slider">Slider goes here</div>
  }
}

Slider.propTypes = {
  className: PropTypes.string,
}

const mapStateToProps = state => ({
  isPlaying: state.app.isPlaying,
})

export default connect(mapStateToProps)(Slider)
