import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import setTimelapseState from 'components/App/actions/setTimelapseState'
import ViewDropdown from './ViewDropdown'
import PauseMenu from './PauseMenu'
import TimeMenu from './TimeMenu'
import SpeedMenu from './SpeedMenu/index'
import YearMenu from './DateMenus/YearMenu'
import MonthMenu from './DateMenus/MonthMenu'
import DayMenu from './DateMenus/DayMenu'

export class Menu extends Component {
  _onPause = e => {
    e.preventDefault()
    const { actions } = this.props
    actions.setTimelapseState({ isPlaying: false, nextStartIndexOnLoad: undefined, nextEndIndexOnLoad: undefined })
  }
  _onViewForward = e => {
    e.preventDefault()
    const { actions, isDone } = this.props
    actions.setTimelapseState({
      direction: 'forward',
      isPlaying: true,
      isDone: isDone ? false : isDone,
      nextStartIndexOnLoad: undefined,
      nextEndIndexOnLoad: undefined,
    })
  }
  _onViewBackward = e => {
    e.preventDefault()
    const { actions, isDone } = this.props
    actions.setTimelapseState({
      direction: 'backward',
      isPlaying: true,
      isDone: isDone ? false : isDone,
      nextStartIndexOnLoad: undefined,
      nextEndIndexOnLoad: undefined,
    })
  }
  render() {
    const { isPlaying, isDone, images = [] } = this.props
    return (
      <div className="main-nav">
        <nav role="navigation" className="timelapse-menu">
          <ul>
            <li className="main-list view-dropdown">
              {(!isPlaying || isDone) && (
                <ViewDropdown onViewBackward={this._onViewBackward} onViewForward={this._onViewForward} />
              )}
              {isPlaying && !isDone && <PauseMenu onPause={this._onPause} />}
            </li>
            <TimeMenu images={images} />
            <SpeedMenu />
            <YearMenu />
            <MonthMenu />
            <DayMenu />
          </ul>
        </nav>
      </div>
    )
  }
}

Menu.propTypes = {
  className: PropTypes.string,
  isDone: PropTypes.bool,
  isPlaying: PropTypes.bool,
  direction: PropTypes.string,
  actions: PropTypes.object,
  images: PropTypes.array,
}

const mapStateToProps = state => ({
  isPlaying: state.app.timelapseState.isPlaying,
  isDone: state.app.timelapseState.isDone,
  direction: state.app.timelapseState.direction,
  images: state.app.images.list,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setTimelapseState }, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)
