import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import setSpeed from 'components/App/actions/setSpeed'

class Speed extends Component {
  _onClick = e => {
    e.preventDefault()
    const { speed, actions } = this.props
    actions.setSpeed(0)
    setTimeout(() => {
      actions.setSpeed(speed)
    }, 0)
  }
  render() {
    const { currentLabel, label } = this.props
    return (
      <li className="speed-li">
        <a href="#" className="speed-a" onClick={this._onClick}>
          {currentLabel === label ? <span className="speed-check">&#10003;</span> : null}
          {label}
        </a>
      </li>
    )
  }
}
Speed.propTypes = {
  actions: PropTypes.object,
  speed: PropTypes.number,
  label: PropTypes.string,
  currentLabel: PropTypes.string,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setSpeed }, dispatch),
})

export default connect(
  null,
  mapDispatchToProps
)(Speed)
