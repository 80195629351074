const getItem = key => {
  try {
    return localStorage.getItem(key)
  } catch (e) {
    return null
  }
}
const setItem = (key, value) => {
  try {
    return localStorage.setItem(key, value)
  } catch (e) {
    return null
  }
}
export default { getItem, setItem }
