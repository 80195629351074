import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from 'common/Icon/index'

class ViewDropdown extends Component {
  render() {
    const { onViewForward, onViewBackward } = this.props
    return (
      <Fragment>
        <a href="#" onClick={e => e.preventDefault()}>
          <Icon icon="film" />
          <span>View</span>
          <Icon icon="dropdown" />
        </a>
        <ul className="dropdown">
          <li>
            <a href="#" onClick={onViewForward}>
              <Icon icon="forward" /> Timelapse forward
            </a>
          </li>
          <li>
            <a href="#" onClick={onViewBackward}>
              <Icon icon="backward" /> Timelapse backward
            </a>
          </li>
        </ul>
      </Fragment>
    )
  }
}
ViewDropdown.propTypes = {
  className: PropTypes.string,
  onViewForward: PropTypes.func,
  onViewBackward: PropTypes.func,
}

export default ViewDropdown
