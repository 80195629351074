import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class Image extends Component {
  componentWillUnmount() {
    const { onUnload, img } = this.props
    onUnload(img)
  }

  render() {
    const { onLoad, src, zIndex, maxHeight, maxWidth, img, showDate, duration } = this.props
    return (
      <div className={`img-wrapper-outer ${img.className}`} style={{ zIndex, transitionDuration: `${duration}ms` }}>
        <div className="img-wrapper-inner">
          <img src={src} onLoad={onLoad} style={{ maxHeight, maxWidth }} />
          {showDate && (
            <h3 className="created-date" style={{ zIndex: 999 }}>
              {img.created}
            </h3>
          )}
        </div>
      </div>
    )
  }
}

Image.propTypes = {
  src: PropTypes.string,
  img: PropTypes.object,
  onLoad: PropTypes.func,
  onUnload: PropTypes.func,
  zIndex: PropTypes.number,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  showDate: PropTypes.bool,
  duration: PropTypes.number,
}

export default Image
