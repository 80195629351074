import { createStore, combineReducers } from 'redux'
import app from 'components/App/reducer'

// Configure the store
export default function configureStore(initialState, middleware) {
  const store = createStore(createReducer(), initialState, middleware)

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {}

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    if (!store.asyncReducers[key]) {
      store.asyncReducers[key] = asyncReducer
      store.replaceReducer(createReducer(store.asyncReducers))
    }
  }
  // Return the modified store
  return store
}

function createReducer(asyncReducers) {
  return combineReducers({
    app,
    ...asyncReducers,
  })
}
