/* eslint-disable react/no-did-mount-set-state */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import qs from 'qs'
import { isAfter, isBefore } from 'date-fns'
import formatDate from 'components/helpers/formatDate'
import fetchImages from 'components/App/actions/fetchImages'
import setTimelapseState from 'components/App/actions/setTimelapseState'
import setGroupState from 'components/App/actions/setGroupState'
import updateLoadedImages from 'components/App/actions/updateLoadedImages'
import updateDate from 'components/App/actions/updateDate'
import { fetchNextImages, fetchPrevImages } from 'components/App/actions/index'
import { GROUP_SIZE } from 'components/App/constants/index'
import Icon from 'common/Icon/index'
import Loading from 'common/Loading/index'
import Image from './Image'

let hrefParams = {}
class Timelapse extends Component {
  state = {}
  componentWillMount() {
    const { actions, location, date } = this.props
    hrefParams = qs.parse(location.search ? location.search.substr(1) : '') || {}
    actions.fetchImages({ ...hrefParams, date })
  }
  componentDidMount() {
    const { height } = this._timelapseNode ? this._timelapseNode.getBoundingClientRect() : {}
    this.setState({
      imageMaxHeight: height ? `${height}px` : 'auto',
    })
  }
  componentWillUpdate(nextProps) {
    const { innerHeight, isPlaying, imageInQueue, isDone, direction, placeholderImage, date } = this.props
    const { loadedImagesMap, actions } = this.props
    const {
      images: nextImages,
      innerHeight: nextInnerHeight,
      isPlaying: nextIsPlaying,
      imageInQueue: nextImageInQueue,
      isDone: nextIsDone,
      placeholderImage: nextPlaceholderImage,
      date: nextDate,
    } = nextProps
    if (date !== nextDate) {
      const params = qs.parse(location.search ? location.search.substr(1) : '') || {}
      actions.fetchImages({ ...params, date: nextDate })
    }
    if (innerHeight !== nextInnerHeight) {
      this._updateMaxSizes(nextInnerHeight)
    }
    if (placeholderImage && !nextPlaceholderImage) {
      if (isPlaying) {
        this._playTimelapse()
      }
    }
    if (isPlaying !== nextIsPlaying || (imageInQueue && !nextImageInQueue)) {
      if ((isDone && !nextIsDone) || direction !== nextProps.direction) {
        const nextImagesGroup = [
          ...nextImages.slice(0, GROUP_SIZE).map((image, i) => ({
            ...image,
            className: i === 0 ? 'current' : 'next',
          })),
        ]
        const firstImage = nextImages[0]
        let placeholderImage = this.props.placeholderImage
        if (!loadedImagesMap[firstImage.url]) {
          placeholderImage = firstImage.url
        }
        actions.setGroupState({
          groupStartIndex: 0,
          groupEndIndex: GROUP_SIZE,
          imagesGroup: nextImagesGroup,
          placeholderImage,
        })
      }
      if (nextIsPlaying) {
        this._playTimelapse()
      } else {
        this._pauseTimelapse()
      }
    }
    if (!isDone && nextIsDone) {
      const { areControlsVisible } = nextProps
      if (!areControlsVisible) {
        actions.setTimelapseState({ areControlsVisible: true })
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { actions, isLoading, nextDate, prevDate } = this.props
    if (!isLoading && prevProps.isLoading) {
      if (!isAfter(new Date(nextDate), new Date())) {
        actions.fetchNextImages({ ...hrefParams, date: nextDate })
      }
      actions.fetchPrevImages({ ...hrefParams, date: prevDate })
    }
  }
  _updateMaxSizes = innerHeight => {
    const { imageRatio } = this.state
    const { height } = this._timelapseNode ? this._timelapseNode.getBoundingClientRect() : {}
    const menuAndSliderHeight = 44 + 44
    const nextHeight = Math.min(innerHeight - menuAndSliderHeight, height)
    this.setState({
      imageMaxHeight: `${nextHeight}px`,
      imageMaxWidth: `${nextHeight / imageRatio}px`,
    })
  }
  _changeGroup = () => {
    const { images, actions, groupStartIndex, groupEndIndex, placeholderImage, loadedImagesMap } = this.props
    const { nextDate, prevDate, direction, replayStartDate } = this.props
    const nextStartIndex = groupStartIndex + 1
    const nextEndIndex = groupEndIndex + 1
    if (nextStartIndex < images.length && !placeholderImage) {
      const prevImages =
        nextStartIndex !== 0
          ? images.slice(Math.max(0, nextStartIndex - GROUP_SIZE - 1), nextStartIndex).map(image => ({
              ...image,
              className: 'prev',
            }))
          : []
      const nextImages = images.slice(nextStartIndex, nextEndIndex).map((image, i) => ({
        ...image,
        className: i === 0 ? 'current' : 'next',
      }))
      const nextImagesGroup = [...prevImages, ...nextImages]
      const nextImage = nextImages[0]
      // const fromStartImages = [...images.slice(0, Math.max(0, nextEndIndex - images.length))]
      if (loadedImagesMap[nextImage.url]) {
        actions.setGroupState({
          imagesGroup: [...nextImagesGroup],
          groupStartIndex: nextStartIndex,
          groupEndIndex: nextEndIndex,
        })
        this._playTimelapse()
      } else {
        actions.setTimelapseState({
          imageInQueue: nextImage.url,
        })
      }
    } else {
      // if end of current group of images
      if (nextStartIndex === images.length) {
        let isDone = false
        let targetDate = nextDate
        if (direction === 'forward') {
          isDone = isAfter(new Date(nextDate), new Date())
        } else {
          isDone = isBefore(new Date(prevDate), new Date(replayStartDate))
          targetDate = prevDate
        }
        if (isDone) {
          actions.setTimelapseState({
            imageInQueue: '',
            isDone: true,
            isPlaying: false,
          })
        } else {
          actions.updateDate(targetDate, 'START')
          actions.setTimelapseState({
            isPlaying: true,
            nextStartIndexOnLoad: undefined,
            nextEndIndexOnLoad: undefined,
          })
        }
      }
      clearTimeout(this._timer)
    }
  }
  _playTimelapse = () => {
    const { speed } = this.props
    clearTimeout(this._timer)
    this._timer = setTimeout(this._changeGroup, speed)
  }
  _pauseTimelapse = () => {
    clearTimeout(this._timer)
  }
  _onLoad = (image, event) => {
    const { imageInQueue, actions, placeholderImage } = this.props
    const { imageMaxWidth, imageMaxHeight } = this.state
    actions.updateLoadedImages({ [image.url]: true })
    if (image.url === imageInQueue) {
      actions.setTimelapseState({ imageInQueue: '' })
    }
    if (placeholderImage === image.url) {
      actions.setGroupState({ placeholderImage: '' })
    }
    if (!imageMaxWidth) {
      const { naturalWidth: width, naturalHeight: height } = event.target
      const imageRatio = height / width
      this.setState({ imageMaxWidth: `${parseInt(imageMaxHeight) / imageRatio}px`, imageRatio })
    }
  }
  _onUnload = image => {
    this.props.actions.updateLoadedImages({ [image.url]: false })
  }
  _onToggle = () => {
    const { isPlaying, isDone, actions, replayStartDate, direction, date } = this.props
    const replayDate = direction === 'forward' ? replayStartDate : formatDate(new Date(), 'YYYY-MM-DD')
    if (isDone) {
      if (replayDate !== date) {
        actions.updateDate(replayDate, 'START')
      }
      setTimeout(() => {
        actions.setTimelapseState({
          isDone: false,
          isPlaying: true,
          nextStartIndexOnLoad: undefined,
          nextEndIndexOnLoad: undefined,
        })
      }, 0)
    } else {
      actions.setTimelapseState({
        isPlaying: !isPlaying,
        nextStartIndexOnLoad: undefined,
        nextEndIndexOnLoad: undefined,
      })
    }
  }
  _onMouseMove = () => {
    clearTimeout(this._movementTimer)
    const { areControlsVisible, actions } = this.props
    if (!areControlsVisible) {
      actions.setTimelapseState({ areControlsVisible: true })
    }
    this._movementTimer = setTimeout(() => {
      if (areControlsVisible) {
        actions.setTimelapseState({ areControlsVisible: false })
      }
    }, 3000)
  }
  _onMouseOut = () => {
    clearTimeout(this._movementTimer)
  }
  render() {
    const { isLoading, error, images, isPlaying, isDone, imageInQueue, areControlsVisible } = this.props
    const { imagesGroup, placeholderImage, speed } = this.props
    const { imageMaxHeight, imageMaxWidth } = this.state
    const imagesLength = images ? images.length : 0
    let contentState = 'LOADING'
    if (error) {
      contentState = 'ERROR'
    } else {
      if (!isLoading && images) {
        if (images.length) {
          contentState = 'LOADED'
        } else {
          contentState = 'NO_RESULTS'
        }
      }
    }
    return (
      <div className="timelapse" ref={el => (this._timelapseNode = el)}>
        {contentState === 'LOADING' && <Loading />}
        {contentState === 'NO_RESULTS' && <p>No results found.</p>}
        {contentState === 'ERROR' && <p>{error}</p>}
        {contentState === 'LOADED' && (
          <Fragment>
            <div className={cx('images-container', { loading: !!placeholderImage })}>
              {imagesGroup.map((image, index) => {
                return (
                  <Image
                    key={image.url}
                    src={image.url}
                    img={image}
                    onLoad={this._onLoad.bind(this, image)}
                    onUnload={this._onUnload}
                    zIndex={imagesLength - index}
                    maxHeight={imageMaxHeight}
                    maxWidth={imageMaxWidth}
                    showDate={true}
                    duration={Math.min(1000, speed)}
                  />
                )
              })}
            </div>
            {!!placeholderImage && (
              <div className="placeholder">
                <Loading />
              </div>
            )}
            {!placeholderImage && (
              <div
                className={cx('timelapse-play-pause', { 'hidden-controls': !areControlsVisible })}
                onClick={this._onToggle}
                onMouseMove={this._onMouseMove}
                onMouseOut={this._onMouseOut}
              >
                <div className={cx('timelapse-loading', { loading: !!imageInQueue })}>
                  <Loading loadingText="" className="large" />
                </div>
                <span className="icon-bg">
                  <Icon icon={isDone ? 'replay' : isPlaying ? 'pause' : 'play'} />
                </span>
              </div>
            )}
          </Fragment>
        )}
      </div>
    )
  }
}
Timelapse.propTypes = {
  className: PropTypes.string,
  actions: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  images: PropTypes.array,
  innerHeight: PropTypes.number,
  innerWidth: PropTypes.number,
  location: PropTypes.object,
  isPlaying: PropTypes.bool,
  isDone: PropTypes.bool,
  direction: PropTypes.string,
  imageInQueue: PropTypes.string,
  areControlsVisible: PropTypes.bool,
  imagesGroup: PropTypes.array,
  groupStartIndex: PropTypes.number,
  groupEndIndex: PropTypes.number,
  placeholderImage: PropTypes.string,
  loadedImagesMap: PropTypes.object,
  speed: PropTypes.number,
  date: PropTypes.string,
  prevDate: PropTypes.string,
  nextDate: PropTypes.string,
  replayStartDate: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    isLoading: state.app.images.isLoading,
    error: state.app.images.error,
    images: state.app.images.list,
    isPlaying: state.app.timelapseState.isPlaying,
    isDone: state.app.timelapseState.isDone,
    direction: state.app.timelapseState.direction,
    imageInQueue: state.app.timelapseState.imageInQueue,
    areControlsVisible: state.app.timelapseState.areControlsVisible,
    imagesGroup: state.app.groupState.imagesGroup,
    groupStartIndex: state.app.groupState.groupStartIndex,
    groupEndIndex: state.app.groupState.groupEndIndex,
    placeholderImage: state.app.groupState.placeholderImage,
    loadedImagesMap: state.app.loadedImagesMap,
    speed: state.app.speed,
    date: state.app.date,
    prevDate: state.app.prevDate,
    nextDate: state.app.nextDate,
    replayStartDate: state.app.replayStartDate,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { fetchImages, setTimelapseState, setGroupState, updateLoadedImages, fetchNextImages, fetchPrevImages, updateDate },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Timelapse))
