import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Icon from 'common/Icon/index'

class ViewDropdown extends Component {
  render() {
    const { onPause } = this.props
    return (
      <Fragment>
        <a href="#" onClick={onPause}>
          <Icon icon="pause" />
          <span>Pause</span>
        </a>
      </Fragment>
    )
  }
}
ViewDropdown.propTypes = {
  className: PropTypes.string,
  onPause: PropTypes.func,
}

export default ViewDropdown
